'use client';

import { CampaignService } from '@/src/lib/api/v3';
import { CampaignCommentsService } from '@/src/lib/services/campaign-comments.service';
import { useAuthHook } from '@/src/lib/store/auth.store';
import { CampaignComment } from '@/src/types';
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import { useEffect, useRef, useState } from 'react';
import CommentComponent from './Comment';

export default function CommentsStream({ campaignId }: { campaignId: number }) {
  const { loading, user } = useAuthHook();
  const commentsContainerRef = useRef<HTMLDivElement>(null);
  const limit = 10;

  const [comments, setComments] = useState<CampaignComment[]>();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isCC, setIsCC] = useState<boolean>(false);

  const [offset, setOffset] = useState<number>(0);
  const [totalComments, setTotalComments] = useState<number>(0);
  const [loadComments, setLoadComments] = useState<boolean>(false);

  useEffect(() => {
    const { current: commentsContainer } = commentsContainerRef;
    if (!commentsContainer) return;

    commentsContainer.addEventListener('scroll', () => {
      const maxScrollLeft =
        commentsContainer.scrollWidth - commentsContainer.clientWidth;
      const scrollPosition = commentsContainer.scrollLeft;

      if (scrollPosition === maxScrollLeft) {
        loadMoreComments();
      }
    });
  }, [commentsContainerRef]);

  useEffect(() => {
    if (loading) return;
    if (!user) {
      setLoadComments(true);
      return;
    }

    const { 'is-admin': isAdmin } = user;

    // if true, then admin can delete and hide, so no need to check further
    if (isAdmin) {
      setIsAdmin(true);
      setLoadComments(true);
      return;
    }

    CampaignService.projectAdminBasicInfo(campaignId)
      .then((res) => {
        const { canEdit } = res.result;

        if (canEdit) {
          setIsCC(true);
        }
      })
      .finally(() => setLoadComments(true));
  }, [loading]);

  useEffect(() => {
    if (!loadComments) return;

    // if we've already loaded comments before
    if (comments) {
      // and those comments are equal to the total comments
      // then return, because there are no more to load
      if (comments.length === totalComments) {
        console.log('no more comments to load');
        return;
      }
    }

    CampaignCommentsService.get(campaignId, limit, offset)
      .then((res) => {
        const { donationComments, totalRecords } = res;
        if (!comments) {
          setComments(donationComments);
        } else {
          setComments([...comments, ...donationComments]);
        }
        setTotalComments(totalRecords);
      })
      .catch((e: Error) => {
        console.error('Error fetching comments:', e.message);
      })
      .finally(() => setLoadComments(false));
  }, [loadComments]);

  const loadMoreComments = () => {
    setOffset(offset + limit);
    setLoadComments(true);
  };

  return (
    <>
      {comments && comments.length > 0 && (
        <>
          <section className="relative sm:py-8 pb-8 rounded-lg sm:bg-neutral-100 overflow-hidden z-0">
            <h1 className="sm:text-xl font-bold text-neutral-900 mb-4 ml-8">
              Words of support
            </h1>
            <div
              ref={commentsContainerRef}
              className="relative flex justify-stretch gap-4 px-8 scroll-p-8 overflow-scroll hide-scrollbar"
            >
              {comments.map((comment, index) => (
                <CommentComponent
                  key={index}
                  comment={comment}
                  isAdmin={isAdmin}
                  isCC={isCC}
                />
              ))}
              {comments.length < totalComments && (
                <div className="flex justify-center items-center min-w-[150px] mr-8">
                  <button
                    className="appearance-none h-full flex items-center justify-center gap-2 grow text-base p-4 rounded-lg hover:bg-neutral-200 transition-all duration-300"
                    onClick={loadMoreComments}
                  >
                    Load more <ArrowRightIcon height={16} />
                  </button>
                </div>
              )}
            </div>
          </section>

          <hr className="my-8 border-rebuild-gray-300 mx-8" />
        </>
      )}
    </>
  );
}
