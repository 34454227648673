'use client';

import { useEffect, useState } from 'react';
import { CampaignService } from '@/src/lib/services/v3';
import { useAuthHook } from '@/src/lib/store/auth.store';
import { CurrencyCodeToSymbol } from '@/src/types';
import { contributionLeafImg } from '@/src/lib/constants';

export default function ContributionBanner({
  projectId,
}: {
  projectId: number;
}) {
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const [contributionDetails, setContributionDetails] = useState<{
    [key: string]: number;
  }>({});
  const { user, loading } = useAuthHook();

  useEffect(() => {
    if (loading || !user) return;

    const getContributionDetails = async () => {
      try {
        const res = await CampaignService.getCampaignContributions({
          projectID: projectId,
        });
        const { result } = res;
        if (!result) {
          throw new Error('No contribution details found');
        }
        setContributionDetails(result?.DonationSum ?? {});
        if (Object.keys(result?.DonationSum).length > 0) {
          setIsBannerVisible(true);
        }
      } catch (err) {
        console.error(err);
      }
    };

    getContributionDetails();
  }, [loading, user]);

  const formatMoney = (value: number, currencyCode: string) => {
    const symbol =
      CurrencyCodeToSymbol[currencyCode as keyof typeof CurrencyCodeToSymbol] ||
      '';
    return `${symbol}${value.toFixed(2)} ${currencyCode}`;
  };

  const donationEntries = Object.entries(contributionDetails);
  const formattedDonations = donationEntries
    .map(([key, value]) => formatMoney(value, key))
    .join(', ');

  return (
    <div
      className={`bg-violet-50 border-violet-300 transition-all duration-700 lg:rounded-lg flex items-center gap-6 px-6 overflow-hidden ${
        isBannerVisible
          ? 'max-h-28 py-4 border-b lg:border lg:mb-4'
          : 'max-h-0 py-0 border-0'
      }`}
    >
      <div>
        <img src={contributionLeafImg} alt="leaf art" />
      </div>
      <div>
        <p className="font-bold text-sm">
          Thank you for supporting this fundraiser!
        </p>
        <p className="text-sm">You’ve contributed {formattedDonations}</p>
      </div>
      <button
        className="ms-auto mt-2 self-start lg:self-center"
        onClick={() => setIsBannerVisible(false)}
      >
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.05806 5.05806C5.30214 4.81398 5.69786 4.81398 5.94194 5.05806L10.5 9.61612L15.0581 5.05806C15.3021 4.81398 15.6979 4.81398 15.9419 5.05806C16.186 5.30214 16.186 5.69786 15.9419 5.94194L11.3839 10.5L15.9419 15.0581C16.186 15.3021 16.186 15.6979 15.9419 15.9419C15.6979 16.186 15.3021 16.186 15.0581 15.9419L10.5 11.3839L5.94194 15.9419C5.69786 16.186 5.30214 16.186 5.05806 15.9419C4.81398 15.6979 4.81398 15.3021 5.05806 15.0581L9.61612 10.5L5.05806 5.94194C4.81398 5.69786 4.81398 5.30214 5.05806 5.05806Z"
            fill="#171717"
          />
        </svg>
      </button>
    </div>
  );
}
