import React from 'react';
import { GivingLevelInputProps } from './interface';
import { Button } from '../ui/Button';

const GivingLevelInput = ({
  currencySymbol,
  setIsCustomInputTouched,
  handleCustomInput,
  setAmount,
  amount,
  quickOptions,
}: GivingLevelInputProps) => {
  const quickOptionsList = [10, 20, 30];
  return (
    <>
      <div className="flex items-center mb-4 relative">
        <span className="text-2xl font-bold absolute left-[24px] pointer-events-none">
          {currencySymbol}
        </span>
        <input
          type="number"
          pattern="\d*"
          className="h-[76px] border border-gray-300 rounded-lg p-6 pr-[67px] text-2xl w-full text-right font-bold appearance-none[&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
          onFocus={(e) => {
            e.target.select();
            setIsCustomInputTouched && setIsCustomInputTouched(true);
          }}
          onChange={handleCustomInput}
          step={1}
          onKeyDown={(e) => {
            if (e.key == '.') e.preventDefault();
          }}
          value={amount === 0 ? '' : amount}
          min={0}
          data-tracking="giving-level-custom-amount-input"
        />
        <span className="text-2xl font-bold absolute right-6 pointer-events-none">
          .00
        </span>
      </div>
      {quickOptions && (
        <div
          className="flex mb-6 gap-4"
          onClick={() => setIsCustomInputTouched(true)}
        >
          {quickOptionsList.map((option) => (
            <Button
              key={option}
              onClick={() => setAmount(option)}
              className="w-full p-3 font-bold shadow-sm border border-gray-600 rounded"
            >
              <p className="text-center">
                {currencySymbol}
                {option}
              </p>
            </Button>
          ))}
        </div>
      )}
    </>
  );
};

export default GivingLevelInput;
