'use client';

import React, { useEffect, useRef, useState } from 'react';
import { Button, buttonVariants } from '../ui/rebuild/Button';
import Drawer from '../Drawer';
import Link from 'next/link';
import config from '@/src/config';
import { GivingLevelsWrapperProps } from './interface';
import CurrencySelector from '../CurrencySelector';
// eslint-disable-next-line import/no-unresolved
import { useSearchParams } from 'next/navigation';
import { builderABTestLink } from '@/src/lib/builder';
import GivingLevelsList from './GivingLevelsList';
import GivingLevelInput from './GivingLevelInput';

export const GivingLevels = ({
  slug,
  givingLevels,
  campaignCurrency,
  showCustomAmountBtn = false,
  customInput = false,
  headingText = 'Your giving amount',
  subHeadingText = 'Select a Giving Level',
  givingHeadingText,
  layout = 'scroll',
  activeGivingLevel,
  amount,
  currencyObject,
  src,
  isActiveCampaign,
  defaultInputAmount = 20,
  handleCustomInput,
  handleSelection,
  setAmount,
}: GivingLevelsWrapperProps) => {
  const searchParams = useSearchParams();
  const defaultGivingLevelId =
    Number(searchParams.get('givingLevelId')) || null;
  const { currencySymbol, currencyCode } = currencyObject;
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [showCustomInput, setShowCustomInput] = useState<boolean>(false);
  const givingLevelsContainer = useRef<HTMLDivElement>(null);
  const [isCustomInputTouched, setIsCustomInputTouched] = useState(false);

  const launchCheckoutUrlQuery = new URLSearchParams();
  launchCheckoutUrlQuery.set('currencyCode', currencyCode);
  launchCheckoutUrlQuery.set('type', 'rebuild');
  // this will be replaced in loop over giving levels
  launchCheckoutUrlQuery.set('givingLevelId', 'GIVING_LEVEL_ID');
  // only set src if it exists, don't allow "src=" which could mess up tracking
  if (src) {
    launchCheckoutUrlQuery.set('src', src);
  }
  const defaultLaunchCheckoutUrl = `/v4/checkout/${slug}?${launchCheckoutUrlQuery.toString()}`;
  const [launchCheckoutUrlTemplate, setLaunchCheckoutUrlTemplate] = useState(
    defaultLaunchCheckoutUrl,
  );

  const toggleCustomInput = () => {
    setShowCustomInput(!showCustomInput);
    if (givingLevelsContainer.current) {
      givingLevelsContainer.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    builderABTestLink({
      id: 'launch-checkout',
      label: 'Giving Level',
      url: defaultLaunchCheckoutUrl,
      replacements: {
        slug,
      },
      query: launchCheckoutUrlQuery,
    }).then(({ url }) => {
      // if any custom URL is set up in Builder.io
      // it will get replaced here
      // this allows various A/B tests and targeting to be done
      setLaunchCheckoutUrlTemplate(url);
      console.info('XXXX:', url);
    });
  }, [launchCheckoutUrlTemplate]);

  useEffect(() => {
    setAmount(currencyObject.amount);
  }, [currencyObject]);

  useEffect(() => {
    // had to get creative with defaultAmount,
    // since it kept changing on load due to exchange rate hook, Now it will only change
    // if the currency selector have been manually touched or the input has been manipulated.
    if (defaultGivingLevelId) {
      setIsCustomInputTouched(true);
    }
    if (!isCustomInputTouched) {
      setAmount(defaultInputAmount);
    }
  }, [currencyObject]);

  return (
    <>
      <div ref={givingLevelsContainer} className="scroll-mt-[110px]">
        <div className="flex items-center justify-between mb-4">
          <div className="flex flex-col gap-2 grow">
            {headingText && (
              <div className="flex items-center justify-between gap-2 grow">
                <div className="flex gap-2 items-baseline">
                  <h2 className="text-xl text-rebuild-dark font-bold">
                    {headingText}
                  </h2>
                </div>
                <div
                  onClick={() =>
                    setIsCustomInputTouched && setIsCustomInputTouched(true)
                  }
                >
                  <CurrencySelector variant="gray" />
                </div>
              </div>
            )}
          </div>
        </div>
        {customInput && !activeGivingLevel && (
          <div>
            <h3 className="text-xl font-bold mb-6">{subHeadingText}</h3>
            <GivingLevelInput
              currencySymbol={currencySymbol}
              setIsCustomInputTouched={setIsCustomInputTouched}
              handleCustomInput={(e) => {
                handleCustomInput(e);
                setShowCustomInput(true);
              }}
              amount={amount}
              setAmount={setAmount}
            />
          </div>
        )}
        {!showCustomInput && (
          <>
            {givingHeadingText && !activeGivingLevel && (
              <h3 className="text-xl font-bold mb-6">{givingHeadingText}</h3>
            )}
            <div className="-mx-8 lg:mx-0">
              <GivingLevelsList
                givingLevels={givingLevels}
                activeGivingLevel={activeGivingLevel}
                layout={layout}
                currencyCode={campaignCurrency}
                isActiveCampaign={isActiveCampaign}
                handleSelection={handleSelection}
                launchCheckoutUrlTemplate={launchCheckoutUrlTemplate}
                setIsCustomInputTouched={setIsCustomInputTouched}
              />
              {/* <div className={showCustomInput ? 'block' : 'hidden'}>
              <GivingLevelInput
                currencySymbol={currencySymbol}
                setIsCustomInputTouched={setIsCustomInputTouched}
                handleCustomInput={handleCustomInput}
                amount={amount}
                setAmount={setAmount}
              />
              <Button
                className={`${buttonVariants({
                  variant: 'dark',
                })} w-full mb-4`}
                size="md"
                asChild={true}
              >
                <Link
                  className="block p-6"
                  href={{
                    pathname: `${config().baseUrl}/checkout/${slug}`,
                    query: {
                      amount: amount,
                      currencyCode: currencyCode,
                      src: src,
                    },
                  }}
                >
                  <span className="me-3">Next</span>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3 10C3 9.58579 3.33579 9.25 3.75 9.25L14.3879 9.25L10.2302 5.29062C9.93159 5.00353 9.92228 4.52875 10.2094 4.23017C10.4965 3.93159 10.9713 3.92228 11.2698 4.20937L16.7698 9.45937C16.9169 9.60078 17 9.79599 17 10C17 10.204 16.9169 10.3992 16.7698 10.5406L11.2698 15.7906C10.9713 16.0777 10.4965 16.0684 10.2094 15.7698C9.92228 15.4713 9.93159 14.9965 10.2302 14.7094L14.3879 10.75L3.75 10.75C3.33579 10.75 3 10.4142 3 10Z"
                      fill="white"
                    />
                  </svg>
                </Link>
              </Button>
            </div> */}
            </div>
          </>
        )}
        {(activeGivingLevel || showCustomInput) && (
          <span
            className="text-rebuild-gray-500 text-sm underline cursor-pointer my-4 block"
            onClick={() => {
              handleSelection && handleSelection(null);
              showCustomInput && toggleCustomInput();
            }}
          >
            Change selection
          </span>
        )}
        {showCustomAmountBtn && isActiveCampaign && (
          <>
            <Button
              className={`${buttonVariants({
                variant: 'outlineDark',
              })} w-full font-bold text-xl rounded-lg lg:hidden`}
              onClick={toggleDrawer}
              size="lg"
              data-tracking="giving-level-custom-amount-button"
            >
              Give a custom amount
            </Button>
            {!showCustomInput && (
              <Button
                className={`${buttonVariants({
                  variant: 'outlineDark',
                })} w-full font-bold text-xl rounded-lg hidden lg:block`}
                onClick={toggleCustomInput}
                size="lg"
                data-tracking="giving-level-custom-amount-button"
              >
                Give a custom amount
              </Button>
            )}
          </>
        )}
      </div>
      <Drawer isOpen={isDrawerOpen} onClose={toggleDrawer}>
        <div className="p-8 pt-16">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold ">Your giving amount</h2>
          </div>
          <GivingLevelInput
            currencySymbol={currencySymbol}
            setIsCustomInputTouched={setIsCustomInputTouched}
            handleCustomInput={handleCustomInput}
            setAmount={setAmount}
            amount={amount}
          />
          <Button
            className={`${buttonVariants({
              variant: 'dark',
            })} w-full mb-4`}
            size="md"
            asChild={true}
          >
            <Link
              className="block p-6"
              href={{
                pathname: `${config().baseUrl}/checkout/${slug}`,
                query: {
                  amount: amount,
                  src: src,
                },
              }}
            >
              <span className="me-3">Next</span>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3 10C3 9.58579 3.33579 9.25 3.75 9.25L14.3879 9.25L10.2302 5.29062C9.93159 5.00353 9.92228 4.52875 10.2094 4.23017C10.4965 3.93159 10.9713 3.92228 11.2698 4.20937L16.7698 9.45937C16.9169 9.60078 17 9.79599 17 10C17 10.204 16.9169 10.3992 16.7698 10.5406L11.2698 15.7906C10.9713 16.0777 10.4965 16.0684 10.2094 15.7698C9.92228 15.4713 9.93159 14.9965 10.2302 14.7094L14.3879 10.75L3.75 10.75C3.33579 10.75 3 10.4142 3 10Z"
                  fill="white"
                />
              </svg>
            </Link>
          </Button>
          <p className="text-gray-500 flex items-center justify-center text-xs">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="m-2"
            >
              <path
                d="M8 6.00006V8.50006M8 1.80957C6.56634 3.16732 4.63042 4.00007 2.5 4.00007C2.46615 4.00007 2.43235 3.99986 2.3986 3.99944C2.13993 4.78618 2 5.6268 2 6.5001C2 10.2278 4.54955 13.36 8 14.2481C11.4505 13.36 14 10.2278 14 6.5001C14 5.6268 13.8601 4.78618 13.6014 3.99944C13.5677 3.99986 13.5339 4.00007 13.5 4.00007C11.3696 4.00007 9.43366 3.16732 8 1.80957ZM8 10.5001H8.005V10.5051H8V10.5001Z"
                stroke="#4E5562"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span>
              Backed by our{' '}
              <a href="" className="underline">
                Trust & Safety guarantee
              </a>
            </span>
          </p>
        </div>
      </Drawer>
    </>
  );
};
