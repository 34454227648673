import { formatDateDifference, toCurrency } from '@/src/lib/utils';
import { ProfileCardProps } from './interface';

export const ProfileCard = ({
  thumbnail,
  name,
  amount,
  date,
  currency,
}: ProfileCardProps) => {
  return (
    <div className="flex last:mb-6">
      <div className="rounded-full overflow-hidden flex items-center justify-center w-[40px] h-[40px] shrink-0">
        <img src={thumbnail} className="object-cover" alt="" />
      </div>
      <div className="ms-3">
        <p className="text-sm mb-1">{name}</p>
        <div className="text-xs">
          <span>
            {toCurrency(Number(amount), currency)} {currency},
          </span>{' '}
          <span className="text-rebuild-gray-500">
            {formatDateDifference(date)}
          </span>
        </div>
      </div>
    </div>
  );
};
