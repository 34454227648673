import { ShareDrawer, ShareDrawerProps } from '.';
import config from '@/src/config';

type CampaignShareDrawerProps = Omit<ShareDrawerProps, 'slug'> & {
  slug: string;
};

export const CampaignShareDrawerWrapper = ({
  ...props
}: CampaignShareDrawerProps) => {
  const { slug, url } = props;
  const campaignShareURL = url
    ? `${config().baseUrl}/${url.replace(/^\/v4/, '')}`
    : `${config().baseUrl}/campaign/${slug}`;

  return <ShareDrawer {...props} url={campaignShareURL} enableInstagramShare />;
};
