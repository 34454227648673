import { Link } from 'react-scroll';

interface Tab {
  name: string;
}

interface TabProps {
  tabs: Tab[];
}

const Tabs = ({ tabs }: TabProps) => (
  <nav className="flex gap-4">
    {tabs.map((tab, index) => (
      <Link
        key={index}
        activeClass="!font-bold !border-gray-600 rounded-none"
        to={tab.name}
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
        className=" border-b-2 border-transparent text-rebuild-gray-600 py-1 rounded cursor-pointer pb-3"
        data-tracking={`tab-${tab.name}`}
      >
        {tab.name}
      </Link>
    ))}
  </nav>
);

export default Tabs;
