'use client';

import { useEffect } from 'react';

interface GTMWithFBProps {
  ccFbPixel: string;
}

const GTMWithFB = ({ ccFbPixel }: GTMWithFBProps) => {
  useEffect(() => {
    (window as any).dataLayer.push({ fbPixel: ccFbPixel });
  }, [ccFbPixel]);
  return <></>;
};

export default GTMWithFB;
