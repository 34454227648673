import { CampaignCommentsService } from '@/src/lib/services/campaign-comments.service';
import { CampaignComment } from '@/src/types';
import { useState } from 'react';

export default function CommentComponent({
  comment,
  isAdmin,
  isCC,
}: {
  comment: CampaignComment;
  isAdmin: boolean;
  isCC: boolean;
}) {
  const [isHidden, setIsHidden] = useState<boolean>(comment.isHidden);

  const toggleHideComment = async () => {
    const { id: commentId, campaignId } = comment;

    await CampaignCommentsService.update(campaignId, commentId, {
      ...comment,
      isHidden: !isHidden,
    });
    setIsHidden(!isHidden);
  };

  return (
    <section className="flex flex-col justify-between gap-2 shrink-0 max-w-[250px] z-[1]">
      <div
        className={`flex flex-col gap-2 grow justify-between self-stretch shrink-0 max-w-[250px] transition-opacity ${
          isHidden && 'opacity-40'
        }`}
      >
        <p className="text-neutral-900 break-words">{comment.comment}</p>
        <p className="text-xs text-gray-500 line-clamp-1">
          {comment.userDisplayName}
          <span className="inline-block min-w-[4px] max-w-[4px] w-1 max-h-[4px] h-1 min-h-[4px] bg-gray-500 rounded-full mx-1 mb-[2px]"></span>
          {comment.userDisplayLocation}
        </p>
      </div>
      {(isAdmin || isCC) && (
        <section className="mt-2">
          {' '}
          {/* className="grid grid-cols-2 gap-2 mt-2" */}
          <button
            className="appearance-none text-purple-600 underline"
            onClick={toggleHideComment}
          >
            {isHidden ? 'Show' : 'Hide'}
          </button>
          {/* This will be for a later iteration */}
          {/* {isAdmin && (
            <Button variant={'ghost'} size={'xs'} className="hover:!bg-red-200">
              Delete
            </Button>
          )} */}
        </section>
      )}
    </section>
  );
}
