'use client';

import { mergeClasses } from '@/src/lib/utils';
import { Duration } from 'luxon';
import { useEffect, useState } from 'react';

type Props = {
  endTime: number | Date;
  textColor?: string;
  className?: string;
  format?: string;
  onCountdownEnd?: () => void;
  textDisplayedOnTimerEnd?: string;
};

export default function CountdownTimer({
  endTime,
  textColor,
  className = '',
  format = 'dd : hh : mm : ss',
  onCountdownEnd,
  textDisplayedOnTimerEnd,
}: Props) {
  const [endTimeInMillis, setEndTimeInMillis] = useState(
    new Date(endTime).getTime(),
  );
  const [remainingTime, setRemainingTime] = useState(
    Math.max(endTimeInMillis - new Date().getTime(), 0),
  );
  const [countdownEnded, setCountdownEnded] = useState(false);

  useEffect(() => {
    setRemainingTime(Math.max(endTimeInMillis - new Date().getTime(), 0));

    const interval = setInterval(() => {
      setRemainingTime(Math.max(endTimeInMillis - new Date().getTime(), 0));
    }, 1000);

    return () => clearInterval(interval);
  }, [endTimeInMillis]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (remainingTime > 0) return;

    setCountdownEnded(true);
    setEndTimeInMillis(new Date().getTime());
    onCountdownEnd && onCountdownEnd();
  }, [remainingTime]);

  return (
    <>
      {!countdownEnded && (
        <span
          className={mergeClasses([
            'text-2xl font-semibold text-white',
            className,
          ])}
          style={{ color: textColor && textColor }}
        >
          <span>{Duration.fromMillis(remainingTime).toFormat(format)}</span>
        </span>
      )}

      {countdownEnded && textDisplayedOnTimerEnd && (
        <span
          className={mergeClasses([
            'text-2xl font-semibold text-white',
            className,
          ])}
          style={{ color: textColor && textColor }}
        >
          {textDisplayedOnTimerEnd}
        </span>
      )}
    </>
  );
}
