import config from '@/src/config';
import { Community } from '@/src/types';

interface CommunitiesSliderProps {
  communities: Community[];
}

export const CommunitiesSlider = ({ communities }: CommunitiesSliderProps) => {
  return (
    <div>
      <h2 className="text-base lg:text-xl text-rebuild-dark font-bold mb-4 mt-8">
        Communities
      </h2>
      <div className="-mx-8 lg:-mx-0">
        <div className="overflow-auto flex gap-7 px-8 lg:px-0 lg:flex-col">
          {communities.map((item, index) => (
            <a
              href={`${config().lg3BaseUrl}${item.URL}`}
              className="group max-w-[200px] flex lg:max-w-full lg:items-center shrink-0"
              key={index}
            >
              <div className="rounded overflow-hidden flex items-center justify-center shrink-0 w-[60px] h-[60px]">
                <img
                  src={item.ImageSrc}
                  alt={item.Name}
                  className="w-full h-full object-cover"
                />
              </div>
              <p className="text-sm ms-3 text-rebuild-gray-500 line-clamp-3 group-hover:underline">
                {item.Name}
              </p>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};
