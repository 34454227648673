'use client';

import config from '@/src/config';
import { CampaignService } from '@/src/lib/api';
import { useAuthHook } from '@/src/lib/store/auth.store';
import { campaignAdminBasicInfoResponse } from '@/src/types';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import {
  CreditCardIcon,
  EyeIcon,
  PencilIcon,
  PresentationChartLineIcon,
  UserIcon,
  ListBulletIcon,
} from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import CampaignStatusBar from './CampaignStatusBar';

export const AdminBar = ({
  slug,
  projectId,
  campaignStatus,
  campaignAdminInfo,
}: {
  slug: string;
  projectId: number;
  campaignStatus: number;
  campaignAdminInfo: campaignAdminBasicInfoResponse;
}) => {
  const { user, loading } = useAuthHook();
  const [isVisible, setIsVisible] = useState(false);
  const [isBarOpen, setIsBarOpen] = useState(false);
  const baseUrl = `${config().lg3BaseUrl}/campaign/${slug}#!`;

  useEffect(() => {
    if (loading) return;
    if (!user) return;

    if (user['is-admin']) {
      setIsVisible(true);
      return;
    }

    CampaignService.projectAdminBasicInfo(projectId).then((res) => {
      const { success, message, result } = res;
      if (!success) {
        console.error(message);
        return;
      }

      const { canEdit } = result;

      if (canEdit) {
        setIsVisible(true);
      }
    });
  }, [loading]);

  return (
    <>
      {isVisible && (
        <>
          {/* DESKTOP BAR */}
          <div className="hidden lg:flex justify-center items-center bg-neutral-800 shadow-sm w-full gap-2 px-8 lg:mt-12">
            <div className="flex justify-center items-center flex-col h-[70px] w-[77px] p-2 bg-primary">
              <EyeIcon className="w-6 h-6 text-white" />
              <span className="text-xs text-white">View</span>
            </div>
            <a
              href={`${baseUrl}/edit`}
              className="flex justify-center items-center flex-col h-[70px] w-[77px] p-2 hover:bg-neutral-900 active:bg-neutral-950 transition-colors duration-300"
              data-tracking="admin-edit-button"
            >
              <PencilIcon className="w-6 h-6 text-neutral-200" />
              <span className="text-xs text-neutral-200">Edit</span>
            </a>
            <a
              href={`${baseUrl}/donors`}
              className="flex justify-center items-center flex-col h-[70px] w-[77px] p-2 hover:bg-neutral-900 active:bg-neutral-950 transition-colors duration-300"
              data-tracking="admin-donors-button"
            >
              <UserIcon className="w-6 h-6 text-neutral-200" />
              <span className="text-xs text-neutral-200">Donors</span>
            </a>
            <a
              href={`${baseUrl}/summary`}
              className="flex justify-center items-center flex-col h-[70px] w-[77px] p-2 hover:bg-neutral-900 active:bg-neutral-950 transition-colors duration-300"
              data-tracking="admin-financial-button"
            >
              <CreditCardIcon className="w-6 h-6 text-neutral-200" />
              <span className="text-xs text-neutral-200">Financial</span>
            </a>

            <a
              href={`${baseUrl}/resources`}
              className="flex justify-center items-center flex-col h-[70px] w-[77px] py-2 px-1 hover:bg-neutral-900 active:bg-neutral-950 transition-colors duration-300"
              data-tracking="admin-dhc-button"
              target="_blank"
            >
              <ListBulletIcon className="w-6 h-6 text-neutral-200" />
              <span className="text-xs text-neutral-200">Helps &amp; Tips</span>
            </a>
            <a
              href={`${baseUrl}/insights`}
              className="flex justify-center items-center flex-col h-[70px] w-[77px] p-2 hover:bg-neutral-900 active:bg-neutral-950 transition-colors duration-300"
              data-tracking="admin-insights-button"
            >
              <PresentationChartLineIcon className="w-6 h-6 text-neutral-200" />
              <span className="text-xs text-neutral-200">Insights</span>
            </a>
          </div>

          {/* MOBILE BAR */}
          <div
            className={`block lg:hidden relative z-20 bottom transition-all duration-200 w-full`}
          >
            <div className="bg-neutral-800">
              <div
                className={`flex justify-end items-center cursor-pointer w-full gap-2 p-3`}
                onClick={() => setIsBarOpen(!isBarOpen)}
              >
                <span className="text-white text-sm">Admin tools</span>
                <ChevronDownIcon
                  className={`w-8 h-8 text-white transition ${
                    isBarOpen ? 'rotate-180' : 'rotate-0'
                  }`}
                />
              </div>
            </div>
            <div
              className={`flex flex-col justify-center items-center bg-neutral-800 shadow-sm cursor-pointer w-full gap-5 transition-all duration-300 overflow-hidden absolute top-14 ${
                isBarOpen ? 'h-[412px] p-3' : 'h-0 p-0'
              }`}
            >
              <div className="flex justify-center items-center h-[52px] p-2 w-full bg-primary rounded-md">
                <EyeIcon className="w-6 h-6 flex-shrink-0 me-2 text-white" />
                <span className="text-xs text-white">View</span>
              </div>
              <a
                href={`${baseUrl}/edit`}
                className="flex justify-center items-center h-[52px] p-2 w-full"
                data-tracking="admin-edit-button"
              >
                <PencilIcon className="w-6 h-6 flex-shrink-0 me-2 text-neutral-200" />
                <span className="text-xs text-neutral-200">Edit</span>
              </a>
              <a
                href={`${baseUrl}/donors`}
                className="flex justify-center items-center h-[52px] p-2 w-full"
                data-tracking="admin-donors-button"
              >
                <UserIcon className="w-6 h-6 flex-shrink-0 me-2 text-neutral-200" />
                <span className="text-xs text-neutral-200">Donors</span>
              </a>
              <a
                href={`${baseUrl}/summary`}
                className="flex justify-center items-center h-[52px] p-2 w-full"
                data-tracking="admin-financial-button"
              >
                <CreditCardIcon className="w-6 h-6 flex-shrink-0 me-2 text-neutral-200" />
                <span className="text-xs text-neutral-200">Financial</span>
              </a>
              {/* <a
                href={`${baseUrlV4}/dhc/referrals?campaignId=${projectId}`}
                className="flex justify-center items-center h-[52px] p-2 w-full"
                data-tracking="admin-dhc-button"
                target="_blank"
              >
                <span className="h-6 pr-2">
                  <DhulHijjahIcon />
                </span>
                <span className="text-xs text-neutral-200">Dhul Hijjah</span>
              </a> */}
              <a
                href={`${baseUrl}/insights`}
                className="flex justify-center items-center h-[52px] p-2 w-full"
                data-tracking="admin-insights-button"
              >
                <PresentationChartLineIcon className="w-6 h-6 flex-shrink-0 me-2 text-neutral-200" />
                <span className="text-xs text-neutral-200">Insights</span>
              </a>
            </div>
          </div>

          <CampaignStatusBar projectId={projectId} />
        </>
      )}
    </>
  );
};
