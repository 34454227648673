'use client';
import React, { useEffect, useState } from 'react';
import Link, { LinkProps } from 'next/link';
import { useSearchParams } from 'next/navigation';
import { builderABTestLink, ABTestUrlProps } from '@/src/lib/builder';

// LinkProps expect "href" but Builder.io calls it "url"
export type ABTestLinkProps = LinkProps & Omit<ABTestUrlProps, 'url'>;

export function ABTestLink({
  id,
  label: fallbackLabel,
  replacements,
  query,
  ...props
}: ABTestLinkProps) {
  const { href: fallbackUrl } = props;
  const src = useSearchParams().get('src') || '';
  const [url, setUrl] = useState(fallbackUrl as string);
  const [label, setLabel] = useState(fallbackLabel);

  useEffect(() => {
    builderABTestLink({
      id,
      url,
      label,
      src,
      replacements,
      query,
    })
      .then(({ url, label }) => {
        setUrl(url);
        setLabel(label);
      })
      // NOTE: builderABTestUrl should already properly return a default upon error
      .catch((err: any) => {
        console.error(
          `Error while trying to build AB Test URL for nav-item ID ${id}:`,
          err,
        );
        throw err;
      });
  }, [label, url]);

  return (
    <Link {...props} href={url}>
      {label}
    </Link>
  );
}

export default ABTestLink;
