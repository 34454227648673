import { Currency, DonorsProps } from '@/src/types';
import { ProfileCard } from '../ProfileCard';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, buttonVariants } from '../ui/rebuild/Button';
import CurrencyFormattedNumber from '../CurrencyNumber';
import { CampaignService } from '@/src/lib/services/v3';
import { CampaignShareDrawerWrapper } from '../ShareDrawer/CampaignShareDrawerWrapper';
import { debounce } from 'lodash';

interface CampaignCommunityProps {
  slug: string;
  supporters: DonorsProps;
  campaignUrl?: string;
  campaignId: number;
}

export const CampaignCommunity = ({
  slug,
  supporters,
  campaignId,
}: CampaignCommunityProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [donors, setDonors] = useState(supporters.donors);
  const [loading, setLoading] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const donorCount = supporters?.count || 0;

  const fetchMoreItems = useCallback(async () => {
    if (donors.length == donorCount) {
      setLoading(false);
      return;
    }
    setLoading(true);
    try {
      const lastId = donors[donors.length - 1]?.ID || 0;
      const { result: CampaignDonationProps } = await CampaignService.getDonors(
        campaignId,
        lastId,
      );
      const data = CampaignDonationProps;
      setDonors((prevDonors) => [...prevDonors, ...data.donors]);
    } catch (error) {
      console.error('Error fetching more items:', error);
    } finally {
      setLoading(false);
    }
  }, [campaignId, donors]);

  const handleScroll = useCallback(() => {
    const container = containerRef.current;
    if (container) {
      const isAtBottom =
        container.scrollHeight - container.scrollTop <=
        container.clientHeight + 100;
      if (isAtBottom && !loading) {
        // Load more items when the user reaches the bottom
        fetchMoreItems();
      }
    }
  }, [containerRef, loading, fetchMoreItems]);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    const container = containerRef.current;
    const debouncedHandleScroll = debounce(handleScroll, 200);
    if (container) {
      container.addEventListener('scroll', debouncedHandleScroll);
      return () => {
        container.removeEventListener('scroll', debouncedHandleScroll);
      };
    }
  }, [handleScroll]);

  return (
    <section>
      <h2 className="text-xl lg:text-2xl text-rebuild-dark font-bold mb-6">
        Donors
      </h2>
      <div className="border border-rebuild-gray-300 rounded-2xl overflow-hidden">
        <div className="bg-rebuild-gray-100 p-6">
          <div className="flex items-end justify-between">
            <div className="mr-3">
              <h3 className="font-bold mb-3">
                Your share could raise over{' '}
                <CurrencyFormattedNumber
                  element="span"
                  amount={77}
                  currencyCode="USD"
                />
              </h3>
              <Button
                className={`${buttonVariants({
                  variant: 'dark',
                })} w-full mb-4`}
                size="lg"
                onClick={toggleDrawer}
              >
                <span className="me-3">Share</span>
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.39837 2.00334C3.17775 1.93926 2.93976 2.00137 2.7786 2.16509C2.61744 2.32882 2.55909 2.56776 2.62665 2.78734L4.65355 9.37476H11.75C12.0952 9.37476 12.375 9.65458 12.375 9.99976C12.375 10.3449 12.0952 10.6248 11.75 10.6248H4.65356L2.62673 17.212C2.55917 17.4315 2.61751 17.6705 2.77868 17.8342C2.93984 17.9979 3.17783 18.06 3.39845 17.9959C8.98061 16.3743 14.1645 13.8176 18.7694 10.507C18.9327 10.3896 19.0296 10.2007 19.0296 9.99955C19.0296 9.79838 18.9327 9.60951 18.7694 9.49208C14.1645 6.18156 8.98054 3.62493 3.39837 2.00334Z"
                    fill="white"
                  />
                </svg>
              </Button>
            </div>
            <img
              src="https://launchgood.s3.amazonaws.com/static/rebuild_assets/root.png"
              alt="leaf"
              className="h-[136px] -mb-6 -mr-10"
            />
          </div>
          <CampaignShareDrawerWrapper
            slug={slug}
            isOpen={isDrawerOpen}
            onClose={toggleDrawer}
            useUserIdAsSrc
          />
          {/* <div className="flex">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="me-1"
            >
              <path
                d="M11.9834 1.90718C12.0546 1.57461 11.8932 1.23571 11.59 1.08152C11.2868 0.927338 10.9179 0.996463 10.6911 1.24994L2.19108 10.7499C1.99385 10.9704 1.9446 11.2861 2.06533 11.5562C2.18607 11.8262 2.45423 12 2.75001 12H9.32227L8.01666 18.0929C7.9454 18.4255 8.10685 18.7644 8.41002 18.9185C8.71318 19.0727 9.08215 19.0036 9.30894 18.7501L17.8089 9.25013C18.0062 9.0297 18.0554 8.71393 17.9347 8.4439C17.814 8.17388 17.5458 8.00003 17.25 8.00003H10.6778L11.9834 1.90718Z"
                fill="#F59E0B"
              />
            </svg>
            <p className="text-sm text-rebuild-gray-500">
              70 people just donated within the last hour
            </p>
          </div> */}
        </div>
        <div className="p-6 pb-0">
          <h3 className="font-bold mb-3">Recent supporters</h3>
          <div
            className="flex flex-col gap-4 max-h-[488px] overflow-auto"
            ref={containerRef}
          >
            {donors.map((item, index) => (
              <ProfileCard
                key={index}
                thumbnail={item.ThumbnailURL}
                name={item.Name}
                amount={item.Amount}
                date={item.Date}
                currency={item.CurrencyCode as Currency}
              />
            ))}
            {loading && (
              <p className="text-sm text-center text-rebuild-gray-400 mb-4">
                Loading...
              </p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
