'use client';

import { Button, buttonVariants } from '@/src/components/ui/rebuild/Button';
import config from '@/src/config';
import React, { useEffect } from 'react';
import { DonateButtonProps } from './interfaces';
import { useSearchParams } from 'next/navigation';
import ABTestLink from '@/src/components/ABTestLink';

export default function DonateButton({
  campaignId,
  slug,
  buttonText,
  campaignEndDate,
  src: _src,
  buttonClasses = 'w-full',
}: DonateButtonProps) {
  const searchParams = useSearchParams();
  const src = _src ? _src : searchParams.get('src') || '';

  // these can be set in Builder.io and replaced, e.g.
  // /v4/checkout/%slug% -> /v4/checkout/some-campaign-url
  const replacements = {
    // slug is needed for 4.0 checkout flow
    slug,
    // these are needed for 3.0 checkout flow
    // /project/donate/%campaignId%/%givingLevel% -> /project/donate/123/
    campaignId,
    givingLevel: '',
  };

  const query: { [key: string]: string } = {
    // if it is 3.0, we need to set redirect URL to come back to 4.0 thank you page
    redirectURL: `${config().baseUrl}/thankyou/${slug}`,
    // MJM: not surfe how this is used
    type: 'rebuild',
  };

  // WARNING: according to Ryan Steadman, passing src= will mess up data tracking
  // hence we are excluding checking it is truthy before setting in query params
  if (src) {
    query.src = src;
  }

  // if campaign is over, we need to disable the button
  const [isActive, setIsActive] = React.useState(false);
  useEffect(() => {
    // eslint-disable-next-line prefer-const
    let intervalId: NodeJS.Timeout;
    const checkDate = () => {
      const currentDate = new Date();
      if (currentDate >= new Date(campaignEndDate)) {
        setIsActive(false);
        clearInterval(intervalId);
      } else {
        setIsActive(true);
      }
    };
    checkDate();

    intervalId = setInterval(() => {
      checkDate();
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Button
      className={`${buttonVariants({
        variant: 'primary',
      })} w-text-center ${
        !isActive && 'pointer-events-none opacity-50 cursor-not-allowed'
      } ${buttonClasses}`}
      size="lg"
      asChild={true}
      data-tracking="donate-button"
      disabled={!isActive}
    >
      <ABTestLink
        id="launch-checkout"
        label={buttonText === 'Support' ? 'DONATE' : buttonText}
        href={`${config().baseUrl}/checkout/${slug}`}
        replacements={replacements}
        query={query}
      />
    </Button>
  );
}
