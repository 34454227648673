import { formatDateDifference } from '@/src/lib/utils';
import React, { useState } from 'react';
import { Button, buttonVariants } from '../ui/rebuild/Button';

interface UpdateCardProps {
  title: string | undefined;
  content: string;
  date: string;
  thumbnail: string;
  name: string;
}

export const UpdateCard = ({
  title,
  content,
  date,
  thumbnail,
  name,
}: UpdateCardProps) => {
  const [isReadMore, setIsReadMore] = useState(false);
  const handleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <div>
      <div className="flex">
        <div className="rounded-full overflow-hidden flex items-center justify-center w-[40px] h-[40px] border border-rebuild-gray-200 shrink-0 me-3">
          <img src={thumbnail} className="object-cover" alt="" />
        </div>
        <div className="w-full overflow-hidden">
          <div className="flex items-center gap-2 mb-3 text-xs">
            <span className="line-clamp-1">{name}</span>
            <svg
              width="2"
              height="2"
              viewBox="0 0 2 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="1" cy="1" r="1" fill="#9CA3AF" />
            </svg>

            <span className="text-rebuild-gray-500 whitespace-nowrap">
              {formatDateDifference(date)}
            </span>
          </div>
          <div className="flex flex-col">
            <div className="relative">
              <div
                className={`${
                  isReadMore ? 'h-auto' : 'max-h-[140px]'
                } overflow-y-hidden relative`}
              >
                <p className="font-bold">{title}</p>
                <div
                  dangerouslySetInnerHTML={{ __html: content }}
                  className="w-full [&>p]:break-words [&_img]:mb-3 [&_img]:rounded-lg [&>img]:max-w-full"
                />
              </div>
              {!isReadMore && (
                <div
                  style={{
                    background:
                      'linear-gradient(0deg, #FFF 40.65%, rgba(255, 255, 255, 0.00) 104.84%)',
                  }}
                  className="bottom-0 w-full absolute h-[70px]"
                ></div>
              )}
            </div>
            <Button
              className={`${buttonVariants({
                variant: 'ghost',
              })} w-full text-primary ${isReadMore && 'mt-4'}`}
              onClick={handleReadMore}
            >
              {isReadMore ? 'Read less' : 'Read more'}
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`${isReadMore && 'rotate-180'} transition`}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.73017 7.70938C6.02875 7.42228 6.50353 7.43159 6.79063 7.73017L10.5 11.6679L14.2094 7.73017C14.4965 7.43159 14.9713 7.42228 15.2698 7.70938C15.5684 7.99647 15.5777 8.47125 15.2906 8.76983L11.0406 13.2698C10.8992 13.4169 10.704 13.5 10.5 13.5C10.296 13.5 10.1008 13.4169 9.95938 13.2698L5.70938 8.76983C5.42228 8.47125 5.43159 7.99647 5.73017 7.70938Z"
                  fill="#4AA567"
                />
              </svg>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
