'use client';

import { HeartIcon } from '@heroicons/react/24/solid';
import { HeartIcon as HeartIconOutline } from '@heroicons/react/24/outline';
import { CampaignService } from '@/src/lib/api/v3/client';
import { useEffect, useState } from 'react';
import { useAuthHook } from '@/src/lib/store/auth.store';

export default function GivingListHeart({
  campaignId,
  added,
  isProcessing = false,
  setIsProcessing = () => {
    return;
  },
  handleSuccess = () => {
    return;
  },
}: {
  campaignId: number;
  added: boolean;
  isProcessing?: boolean;
  setIsProcessing?: (value: boolean) => void;
  handleSuccess?: () => void;
}) {
  const [isFavorite, setIsFavorite] = useState(added);
  const { user } = useAuthHook();

  function toggleHeart(verb: 'add' | 'remove') {
    if (isProcessing) return;

    setIsProcessing(true);

    CampaignService.userFavorite({ verb, project_id: campaignId })
      .then((data) => {
        const { success, message, result } = data;

        if (!success) {
          console.error(message);
          return;
        }

        const { favorited } = result;
        setIsFavorite(favorited);

        handleSuccess();
      })
      .catch(console.error)
      .finally(() => {
        setIsProcessing(false);
      });
  }

  useEffect(() => {
    setIsFavorite(added);
  }, [added]);

  return (
    <>
      {user && (
        <>
          {isFavorite && (
            <button
              className={`appearance-none`}
              onClick={() => toggleHeart('remove')}
              data-tracking="remove-favorite-button"
              disabled={isProcessing}
            >
              <HeartIcon className={`w-6 h-6 fill-white`} />
            </button>
          )}
          {!isFavorite && (
            <button
              className={`appearance-none`}
              onClick={() => toggleHeart('add')}
              data-tracking="add-favorite-button"
              disabled={isProcessing}
            >
              <HeartIconOutline className={`w-6 h-6 stroke-white`} />
            </button>
          )}
        </>
      )}
    </>
  );
}
