'use client';

import { useState } from 'react';
import { Button, buttonVariants } from '../ui/rebuild/Button';
import { mergeClasses } from '@/src/lib';
import { CampaignShareDrawerWrapper } from '../ShareDrawer/CampaignShareDrawerWrapper';

type Props = {
  campaignUrl?: string;
  slug: string;
  type?: 'thankyou' | 'campaign' | string;
  size?: 'md' | 'sm' | 'lg' | 'icon' | null | undefined;
  buttonText: string;
  className?: string;
  useUserIdAsSrc?: boolean;
  src?: string;
};

export const ThankyouShare = ({
  campaignUrl,
  slug,
  className,
  type,
  size = 'md',
  buttonText,
  useUserIdAsSrc = false,
  src,
}: Props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <>
      <Button
        className={`${mergeClasses(
          className,
          buttonVariants({
            variant: type == 'campaign' ? 'outlineGray' : 'outlineDark',
          }),
        )}`}
        size={size ? size : 'md'}
        onClick={() => setIsDrawerOpen(true)}
        data-tracking={`${type ? type : 'campaign'}-share-button`}
      >
        <span>{buttonText}</span>
        {type == 'thankyou' && (
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="black"
            xmlns="http://www.w3.org/2000/svg"
            className="ms-3 group-hover:fill-white transition-colors duration-300"
          >
            <path d="M3.39849 2.00334C3.17787 1.93926 2.93988 2.00137 2.77872 2.16509C2.61756 2.32882 2.55921 2.56776 2.62677 2.78734L4.65367 9.37476H11.7501C12.0953 9.37476 12.3751 9.65458 12.3751 9.99976C12.3751 10.3449 12.0953 10.6248 11.7501 10.6248H4.65368L2.62685 17.212C2.55929 17.4315 2.61764 17.6705 2.7788 17.8342C2.93996 17.9979 3.17795 18.06 3.39857 17.9959C8.98073 16.3743 14.1646 13.8176 18.7695 10.507C18.9329 10.3896 19.0297 10.2007 19.0297 9.99955C19.0297 9.79838 18.9328 9.60951 18.7695 9.49208C14.1646 6.18156 8.98067 3.62493 3.39849 2.00334Z" />
          </svg>
        )}
      </Button>
      <CampaignShareDrawerWrapper
        slug={slug}
        url={campaignUrl}
        isOpen={isDrawerOpen}
        onClose={toggleDrawer}
        useUserIdAsSrc={useUserIdAsSrc}
        src={src}
      />
    </>
  );
};
