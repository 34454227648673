import { TimelineProps } from '@/src/types';
import { UpdateCard } from '../UpdateCard';

interface CampaignUpdatesProps {
  timelineData: TimelineProps;
  thumbnail: string;
  name: string;
}

export const CampaignUpdates = ({
  timelineData,
  thumbnail,
  name,
}: CampaignUpdatesProps) => {
  const filteredTimeline = timelineData.items.filter(
    (item) => item.subtype == 'update',
  );

  return (
    <section>
      <h2 className="text-xl lg:text-2xl text-rebuild-dark font-bold mb-8 flex items-center gap-2">
        Updates{' '}
        <span className="flex items-center justify-center rounded-full w-[22px] h-[22px] text-2xs border border-rebuild-gray-300 text-rebuild-gray-500">
          {filteredTimeline.length}
        </span>
      </h2>
      <div className="flex gap-6 flex-col">
        {filteredTimeline.map((item, index) => (
          <UpdateCard
            title={item.title}
            content={item.body}
            date={item.date}
            thumbnail={thumbnail}
            name={name}
            key={index}
          />
        ))}
      </div>
    </section>
  );
};
