import { Button, buttonVariants } from '../ui/Button';
import { useEffect, useRef, useState } from 'react';
import { CampaignService } from '@/src/lib/api';
import { CampaignService as CustomCampaignService } from '@/src/lib/services/v3';
import {
  CampaignAdminBarInfo,
  ProjectStatePayload,
  ProjectStatusText,
} from '@/src/types';
import { useRouter } from 'next/navigation';

type CampaignStatusBarProps =
  | {
      backgroundColor: string;
      label: string;
      headingColor: string;
      showButton: true;
      buttonText: string;
    }
  | {
      backgroundColor: string;
      label: string;
      headingColor: string;
      showButton: false;
    };

export default function CampaignStatusBar({
  projectId,
}: {
  projectId: number;
}) {
  const [adminInfo, setAdminInfo] = useState<CampaignAdminBarInfo>();
  const [campaignStatusBarProps, setCampaignStatusBarProps] =
    useState<CampaignStatusBarProps>();
  const [errorText, setErrorText] = useState<string>();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const router = useRouter();

  useEffect(() => {
    CampaignService.projectBarInfo(projectId).then((res) => {
      const { success, message, result } = res;
      if (!success) {
        console.error(message);
        return;
      }

      setAdminInfo(result);
    });
  }, []);

  useEffect(() => {
    if (!adminInfo) return;

    const { project } = adminInfo;
    const { status } = project;

    switch (status) {
      case ProjectStatusText.SUBMIT_FOR_REVIEW:
        setCampaignStatusBarProps({
          backgroundColor: 'bg-orange-100',
          headingColor: 'text-orange-500',
          label: 'Submit your fundraiser for review',
          showButton: true,
          buttonText: 'Submit for review',
        });
        break;
      case ProjectStatusText.IN_REVIEW:
        setCampaignStatusBarProps({
          backgroundColor: 'bg-orange-100',
          headingColor: 'text-orange-500',
          label: 'Your fundraiser is currently under review.',
          showButton: false,
        });
        break;
      case ProjectStatusText.REJECTED:
        setCampaignStatusBarProps({
          backgroundColor: 'bg-red-100',
          headingColor: 'text-red-500',
          label:
            'Your fundraiser has been rejected. Check your email for details.',
          showButton: false,
        });
        break;
      case ProjectStatusText.GO_LIVE:
        setCampaignStatusBarProps({
          backgroundColor: 'bg-primary/10',
          headingColor: 'text-primary',
          label: 'Your fundraiser is ready to go live!',
          showButton: true,
          buttonText: 'Go live!',
        });
        break;
      case ProjectStatusText.LIVE:
        setCampaignStatusBarProps({
          backgroundColor: 'bg-primary/10',
          headingColor: 'text-primary',
          label: 'Your fundraiser is live!',
          showButton: false,
        });
        break;
    }
  }, [adminInfo]);

  const handleButtonClick = async () => {
    if (!adminInfo) return;

    const { project } = adminInfo;
    const { status } = project;

    switch (status) {
      case ProjectStatusText.SUBMIT_FOR_REVIEW:
        CustomCampaignService.requirementsCheck(projectId).then(async (res) => {
          const { success, message, result } = res;
          if (!success) {
            console.error(message);
            setErrorText(`Error submitting campaign for review: ${message}`);
            return;
          }

          const { canSubmit, missingRequired } = result;
          if (!canSubmit) {
            setErrorText(
              `You must complete all requirements before submitting your campaign for review. <br><br><strong>Missing requirements:</strong>
              <br> ${missingRequired.join('<br>')}`,
            );
            return;
          }

          const submitForReviewPayload: ProjectStatePayload = {
            state: 'submit-project',
            id: projectId.toString(),
            params: {
              coaching: false,
              DialogMsg: 'nocall',
              option: 'nocall',
              urgent: false,
            },
          };

          const newProjectState = await updateProjectState(
            submitForReviewPayload,
          );
          if (newProjectState) {
            setAdminInfo(newProjectState);
            // refreshes server side data on page without reload
            router.refresh();
          }
        });
        break;
      case ProjectStatusText.GO_LIVE:
        const goLivePayload: ProjectStatePayload = {
          state: 'start-project',
          id: projectId.toString(),
        };

        const newProjectState = await updateProjectState(goLivePayload);

        if (newProjectState) {
          setAdminInfo(newProjectState);
          // refreshes server side data on page without reload
          router.refresh();
        }
    }
  };

  const updateProjectState = async (payload: ProjectStatePayload) => {
    const res = await CustomCampaignService.changeProjectState(payload);
    const { success, message, result } = res;
    if (!success) {
      console.error(message);
      setErrorText(`Error updating fundraiser state: ${message}`);
      return;
    }

    return result;
  };

  return (
    <>
      {campaignStatusBarProps && (
        <section
          className={`w-full p-4 flex flex-wrap justify-center items-center gap-4 mb-6 ${campaignStatusBarProps.backgroundColor}`}
        >
          <h1
            className={`text-lg font-semibold ${campaignStatusBarProps.headingColor}`}
          >
            {campaignStatusBarProps.label}
          </h1>
          {campaignStatusBarProps.showButton && (
            <Button
              className={`${buttonVariants({
                variant: 'primary',
                size: 'base',
              })} w-fit`}
              ref={buttonRef}
              onClick={handleButtonClick}
              data-tracking="update-campaign-status-button"
            >
              {campaignStatusBarProps.buttonText}
            </Button>
          )}
        </section>
      )}
      {errorText && (
        <section className="w-full p-4 bg-red-100 text-center -mt-6 mb-6">
          <p
            className="text-sm text-red-500"
            dangerouslySetInnerHTML={{ __html: errorText }}
          ></p>
        </section>
      )}
    </>
  );
}
