'use client';
import { SelectHTMLAttributes } from 'react';
import { SelectOption } from '@/src/types';
import { mergeClasses } from '@/src/lib/utils';

type SelectProps = {
  options: SelectOption[];
  placeholderOption?: SelectOption;
  defaultOption?: SelectOption;
  onValueChange: (value: string) => void;
  variant?: 'white' | 'gray';
  dataTracking?: string;
  name?: string;
  optionClasses?: string;
} & SelectHTMLAttributes<HTMLSelectElement>;

//destructure more props if needed
export default function Select({
  options,
  className,
  defaultOption,
  variant = 'gray',
  placeholderOption,
  dataTracking,
  name,
  onValueChange,
  optionClasses,
  ...props // eslint-disable-line @typescript-eslint/no-unused-vars
}: SelectProps) {
  const variantClass = variant === 'white' ? 'bg-white' : 'bg-neutral-100';
  return (
    <select
      value={defaultOption?.value}
      defaultValue={defaultOption?.value}
      onChange={(e) => onValueChange(e.target.value)}
      className={mergeClasses(
        variantClass,
        'relative box-border border-none pl-2 pr-8 py-1 rounded-md text-xs cursor-pointer',
        className,
      )}
      data-tracking={dataTracking}
      name={name}
    >
      {!!placeholderOption && (
        <option value={placeholderOption.value} disabled hidden selected>
          {placeholderOption.label}
        </option>
      )}
      {options.map((option, index) => (
        <option key={index} value={option.value} className={optionClasses}>
          {option.label}
        </option>
      ))}
    </select>
  );
}
