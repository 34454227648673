import React from 'react';
import { GivingLevelsListProps } from './interface';
import GivingLevelCard from './GivingLevelCard';

const GivingLevelsList = ({
  givingLevels,
  currencyCode,
  activeGivingLevel,
  isActiveCampaign,
  launchCheckoutUrlTemplate,
  showClaimed,
  layout,
  handleSelection,
  setIsCustomInputTouched,
}: GivingLevelsListProps) => {
  const gridList = 'flex flex-col gap-4 px-8 mb-4 lg:px-0';
  const scrollList =
    'flex lg:flex-col gap-4 overflow-auto px-8 lg:px-0 [&>li]:lg:w-full [&>li]:w-[258px]';

  return (
    <ul className={`${layout === 'scroll' ? scrollList : gridList} py-0.5`}>
      {givingLevels?.map((givingLevel, index) => (
        <li
          className={`border shadow-sm border-rebuild-dark rounded-lg flex-shrink-0 cursor-pointer transition-colors duration-200 hover:bg-neutral-50 active:bg-neutral-100  ${
            !activeGivingLevel || givingLevel.ID === activeGivingLevel.ID
              ? ''
              : 'hidden'
          } ${
            givingLevel.Limit > 0 &&
            givingLevel.PrizeAwarded >= givingLevel.Limit &&
            '!cursor-not-allowed'
          } ${givingLevel === activeGivingLevel && 'bg-neutral-50'}`}
          key={index}
        >
          <GivingLevelCard
            givingLevel={givingLevel}
            currencyCode={currencyCode}
            isActiveCampaign={isActiveCampaign}
            showClaimed={showClaimed}
            givingLevelLink={launchCheckoutUrlTemplate}
            setIsCustomInputTouched={setIsCustomInputTouched}
            handleSelection={handleSelection}
          />
        </li>
      ))}
    </ul>
  );
};

export default GivingLevelsList;
