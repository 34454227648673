'use client';

import CountUp from 'react-countup';
import ProgressBar from '../ProgressBar';
import { Currency } from '@/src/types';
import CurrencyFormattedNumber from '../CurrencyNumber';
import { useExchangeRateHook } from '@/src/lib/store/exchange-rate';

// to avoid hydration errors, we need to import CountdownTimer dynamically
import dynamic from 'next/dynamic';
import { useState } from 'react';
import CountdownTimer from '../CountdownTimer/CountdownTimer';
// const CountdownTimer = dynamic(
//   () => import('@/src/components/CountdownTimer/CountdownTimer'),
//   { ssr: false },
// );

interface CampaignGoalProps {
  campaignGoal: string;
  pledgeAmount: string;
  currencyCode: Currency;
  currencySymbol: string;
  supporters: number;
  remainingDays: number;
  endDate: Date;
  progress: number;
}

export default function CampaignGoal({
  campaignGoal,
  pledgeAmount,
  currencyCode,
  supporters,
  remainingDays,
  endDate,
  progress,
}: CampaignGoalProps) {
  // need to convert pledgeAmount to int to use in CountUp as we receive it as string from API
  const pledgeAmountInt = parseInt(pledgeAmount);
  const currencyObject = useExchangeRateHook(
    pledgeAmountInt,
    currencyCode,
    false,
  );
  const countStart =
    currencyObject.amount < 100
      ? currencyObject.amount / 2
      : currencyObject.amount - 100;

  const [countdownEnded, setCountdownEnded] = useState(false);

  return (
    <div>
      <div className="flex lg:flex-col lg:items-center items-end mb-1 lg:mb-2">
        <div className="text-2xl lg:text-5xl lg:font-semibold text-rebuild-primary lg:mb-2 font-bold me-1">
          <span>{currencyObject.currencySymbol}</span>
          <CountUp
            start={countStart}
            end={currencyObject.amount}
            prefix={currencyObject.currencySymbol}
            duration={4}
            formattingFn={(value) => value.toLocaleString()}
          />
        </div>
        <p className="text-sm text-rebuild-gray-500 mb-[3px]">
          raised of{' '}
          <CurrencyFormattedNumber
            element="span"
            amount={Number(campaignGoal)}
            currencyCode={currencyCode}
            appendCurrencyCode={true}
          />{' '}
          goal
        </p>
      </div>
      <div className="mb-2">
        <ProgressBar
          backgroundColor="#E5E5E5"
          visualParts={[
            {
              percentage: progress,
              color: '#4AA567',
            },
          ]}
        />
      </div>
      {(supporters || remainingDays) && (
        <div className="text-sm text-rebuild-gray-500 lg:text-center">
          {supporters > 0 && (
            <>
              <span className="text-rebuild-dark">{supporters}</span> supporter
              {supporters > 1 ? 's' : ''},{' '}
            </>
          )}
          {remainingDays > 1 && (
            <>
              <span className="text-black-400">{remainingDays}</span> days left
            </>
          )}
          {remainingDays <= 1 && (
            <>
              {!countdownEnded && (
                <>
                  <CountdownTimer
                    endTime={endDate}
                    className="text-black-400 text-sm font-normal"
                    format="hh'h' : mm'm' : ss's'"
                    onCountdownEnd={() => setCountdownEnded(true)}
                  />{' '}
                  left
                </>
              )}

              {countdownEnded && (
                <span className="text-black-400 text-sm font-normal">
                  Project closed
                </span>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}
