import React, { useEffect, useState } from 'react';
import { ProgressBarProps } from './interface';

const ProgressBar = ({
  visualParts = [
    {
      percentage: 0,
      color: 'white',
    },
  ],
  height = 4,
  backgroundColor = '#E5E5E5',
  transitionTime = 3,
}: ProgressBarProps) => {
  const [width, setWidth] = useState(
    visualParts.map(() => {
      return 0;
    }),
  );

  useEffect(() => {
    requestAnimationFrame(() => {
      setWidth(
        visualParts.map((item) => {
          return item.percentage;
        }),
      );
    });
  }, [visualParts]);

  return (
    <>
      <div
        className="rounded-full overflow-hidden"
        style={{
          height: `${height}px`,
          backgroundColor: backgroundColor,
        }}
      >
        {visualParts.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                width: `${width[index]}%`,
                height: '100%',
                backgroundColor: item.color,
                transition: `all ${transitionTime}s`,
              }}
            />
          );
        })}
      </div>
    </>
  );
};

export default ProgressBar;
