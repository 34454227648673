'use client';

import { CampaignService } from '@/src/lib/api';
import { useAuthHook } from '@/src/lib/store/auth.store';
import { useEffect, useState } from 'react';
import GivingListHeart from '../CampaignCard/GivingListHeart';
import { CampaignHeaderOverlayProps } from '../CampaignHeaderOverlay/interfaces';
import { ZakatDrawer } from '../ZakatDrawer';

export default function CampaignHeaderButtons({
  campaignId,
  zakatVerified,
}: Omit<CampaignHeaderOverlayProps, 'campaignVideo'>) {
  const [isFavorite, setIsFavorite] = useState(false);
  const { loading } = useAuthHook();

  useEffect(() => {
    if (loading) return;

    CampaignService.cardsSupportInfo({
      ids: [campaignId],
    }).then((res) => {
      res.result[0].favorited && setIsFavorite(true);
    });
  }, [loading]);

  return (
    <section
      className="absolute top-0 left-0 w-full p-6"
      style={{
        background:
          'linear-gradient(0deg, transparent 5.14%, rgba(0, 0, 0, 0) 14.36%, rgba(0, 0, 0, 0.5) 77.82%, rgba(0, 0, 0, 0.5) 100%)',
      }}
    >
      <div className="flex justify-end gap-3 items-center">
        {zakatVerified && <ZakatDrawer />}
        <GivingListHeart campaignId={campaignId} added={isFavorite} />
      </div>
    </section>
  );
}
