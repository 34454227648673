export const TrustAndSafety = () => {
  return (
    <div className="p-8 lg:bg-rebuild-gray-100 lg:rounded-lg overflow-hidden">
      <h2 className="font-bold text-xl">Give with peace of mind</h2>
      <h3 className="font-bold mb-4">on LaunchGood</h3>
      <p className="text-rebuild-gray-500 mb-6">
        Every donation is backed by our{' '}
        <a href="/safety" className="underline">
          Trust & Safety guarantee.
        </a>{' '}
      </p>
      <div className="flex flex-col lg:grid lg:grid-cols-2 gap-4 columns-2">
        <div className="flex">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className="shrink-0 me-4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.125 2.25H5.625C5.00368 2.25 4.5 2.75368 4.5 3.375V20.625C4.5 21.2463 5.00368 21.75 5.625 21.75H18.375C18.9963 21.75 19.5 21.2463 19.5 20.625V11.625M10.125 2.25H10.5C15.4706 2.25 19.5 6.27944 19.5 11.25V11.625M10.125 2.25C11.989 2.25 13.5 3.76104 13.5 5.625V7.125C13.5 7.74632 14.0037 8.25 14.625 8.25H16.125C17.989 8.25 19.5 9.76104 19.5 11.625M9 15L11.25 17.25L15 12"
              stroke="#0F172A"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div>
            <h3 className="font-bold">Rigorously vetted</h3>
            <p className="text-rebuild-gray-500">
              Each fundraiser is reviewed by our team to ensure they are
              legitimate.
            </p>
          </div>
        </div>
        <div className="flex">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className="shrink-0 me-4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 3.75C10.6232 3.75 9.3447 4.16143 8.27806 4.86788C7.93272 5.09661 7.46736 5.00207 7.23863 4.65674C7.00991 4.3114 7.10444 3.84603 7.44978 3.61731C8.75444 2.7532 10.3196 2.25 12 2.25C16.5564 2.25 20.25 5.94365 20.25 10.5C20.25 13.5153 19.6759 16.3983 18.6299 19.0442C18.4776 19.4294 18.0419 19.6182 17.6567 19.466C17.2715 19.3137 17.0827 18.8779 17.2349 18.4927C18.2127 16.0196 18.75 13.3235 18.75 10.5C18.75 6.77208 15.7279 3.75 12 3.75ZM6.15674 5.73863C6.50208 5.96735 6.59661 6.43272 6.36789 6.77806C5.66143 7.84469 5.25001 9.12316 5.25001 10.5C5.25001 12.1132 4.78656 13.6204 3.98462 14.893C3.76378 15.2434 3.30068 15.3485 2.95024 15.1276C2.59981 14.9068 2.49475 14.4437 2.71559 14.0933C3.37095 13.0533 3.75001 11.8222 3.75001 10.5C3.75001 8.81959 4.25321 7.25443 5.11731 5.94977C5.34604 5.60444 5.81141 5.5099 6.15674 5.73863ZM12 7.5C10.3432 7.5 9.00001 8.84315 9.00001 10.5C9.00001 13.5997 7.82404 16.4266 5.89465 18.5556C5.6165 18.8626 5.1422 18.8859 4.83527 18.6077C4.52834 18.3296 4.505 17.8553 4.78315 17.5484C6.47221 15.6845 7.50001 13.2132 7.50001 10.5C7.50001 8.01472 9.51473 6 12 6C14.4853 6 16.5 8.01472 16.5 10.5C16.5 11.0472 16.4778 11.5897 16.4334 12.1264C16.3993 12.5392 16.037 12.8462 15.6242 12.8121C15.2114 12.778 14.9044 12.4157 14.9385 12.0029C14.9794 11.5078 15 11.0066 15 10.5C15 8.84315 13.6569 7.5 12 7.5ZM12.0003 9.75C12.4145 9.75 12.7503 10.0858 12.7503 10.5C12.7503 14.4082 11.3259 17.9855 8.96887 20.7384C8.69948 21.053 8.22602 21.0897 7.91138 20.8203C7.59674 20.5509 7.56007 20.0775 7.82946 19.7628C9.9626 17.2715 11.2503 14.0371 11.2503 10.5C11.2503 10.0858 11.5861 9.75 12.0003 9.75ZM15.2387 14.9331C15.6371 15.0467 15.8678 15.4617 15.7542 15.8601C15.1843 17.8576 14.3041 19.7239 13.1694 21.4035C12.9375 21.7467 12.4713 21.837 12.128 21.6051C11.7848 21.3732 11.6946 20.907 11.9264 20.5637C12.974 19.0132 13.7861 17.291 14.3118 15.4485C14.4254 15.0502 14.8404 14.8194 15.2387 14.9331Z"
              fill="#0F172A"
            />
          </svg>

          <div>
            <h3 className="font-bold">Completely secure</h3>
            <p className="text-rebuild-gray-500">
              Our encryption and security protocols protect your personal
              information and financial data.
            </p>
          </div>
        </div>
        <div className="flex">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className="shrink-0 me-4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 1.5C12.4142 1.5 12.75 1.83579 12.75 2.25V4.5C12.75 4.91421 12.4142 5.25 12 5.25C11.5858 5.25 11.25 4.91421 11.25 4.5V2.25C11.25 1.83579 11.5858 1.5 12 1.5ZM5.63604 4.13604C5.92893 3.84315 6.40381 3.84315 6.6967 4.13604L8.28769 5.72703C8.58058 6.01992 8.58058 6.4948 8.28769 6.78769C7.9948 7.08058 7.51992 7.08058 7.22703 6.78769L5.63604 5.1967C5.34315 4.90381 5.34315 4.42893 5.63604 4.13604ZM18.364 4.13604C18.6569 4.42893 18.6569 4.90381 18.364 5.1967L16.773 6.78769C16.4801 7.08058 16.0052 7.08058 15.7123 6.78769C15.4194 6.4948 15.4194 6.01992 15.7123 5.72703L17.3033 4.13604C17.5962 3.84315 18.0711 3.84315 18.364 4.13604ZM11.5484 8.63179C11.8602 8.54824 12.1905 8.67359 12.3684 8.94299L17.5955 16.8599C17.7627 17.113 17.7609 17.4419 17.591 17.6932C17.421 17.9445 17.1165 18.0687 16.8193 18.0079L14.722 17.5787L15.7668 21.4777C15.874 21.8778 15.6365 22.289 15.2364 22.3963C14.8363 22.5035 14.4251 22.266 14.3179 21.8659L13.2732 17.967L11.6717 19.3872C11.4447 19.5884 11.1189 19.6332 10.8461 19.5005C10.5733 19.3678 10.4073 19.0839 10.4254 18.7811L10.9939 9.3113C11.0132 8.98905 11.2366 8.71534 11.5484 8.63179ZM3 10.5C3 10.0858 3.33579 9.75 3.75 9.75H6C6.41421 9.75 6.75 10.0858 6.75 10.5C6.75 10.9142 6.41421 11.25 6 11.25H3.75C3.33579 11.25 3 10.9142 3 10.5ZM17.25 10.5C17.25 10.0858 17.5858 9.75 18 9.75H20.25C20.6642 9.75 21 10.0858 21 10.5C21 10.9142 20.6642 11.25 20.25 11.25H18C17.5858 11.25 17.25 10.9142 17.25 10.5ZM8.28769 14.2123C8.58058 14.5052 8.58058 14.9801 8.28769 15.273L6.6967 16.864C6.40381 17.1569 5.92893 17.1569 5.63604 16.864C5.34315 16.5711 5.34315 16.0962 5.63604 15.8033L7.22703 14.2123C7.51992 13.9194 7.9948 13.9194 8.28769 14.2123Z"
              fill="#0F172A"
            />
          </svg>
          <div>
            <h3 className="font-bold">Super simple</h3>
            <p className="text-rebuild-gray-500">
              We take great care in providing a world-class giving experience.
            </p>
          </div>
        </div>
        <div className="flex justify-center lg:-mb-[65px]">
          <img
            src="https://launchgood.s3.amazonaws.com/static/rebuild_assets/campaign-art.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};
