'use client';
import {
  Currency,
  CurrencyCodeToSymbol,
  CurrencyFlags,
  SelectOption,
} from '@/src/types';
import Select from '../ui/Select';
import { useContext, useEffect, useState } from 'react';
import { FxService } from '@/src/lib/services';
import { UserContext } from '@/src/lib/context';
import { useAuthHook } from '@/src/lib/store/auth.store';
import { UserPreferencesService } from '@/src/lib/services/v3';

export default function CurrencySelector({
  variant,
}: {
  variant: 'white' | 'gray';
}) {
  const { currency: userSetCurrency, setCurrency } = useContext(UserContext);
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [defaultOption, setDefaultOption] = useState<SelectOption>();

  const { user } = useAuthHook();

  useEffect(() => {
    if (options.length > 0) return;
    FxService.getSupportedCurrencies().then((res) => {
      const { result: currencies } = res;
      if (!currencies) return;
      const tempOptions: SelectOption[] = [];

      currencies.forEach((currency: string) => {
        const currencyCode = currency as Currency;
        const label = `${CurrencyFlags[currencyCode]} ${CurrencyCodeToSymbol[currencyCode]} ${currency}`;
        tempOptions.push({
          label,
          value: currency,
        });
      });

      setOptions(tempOptions);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!options.length) return;
    setDefaultOption(
      options.find((option) => option.value === userSetCurrency),
    );
  }, [options, userSetCurrency]);

  async function onValueChange(value: string) {
    if (user) {
      await UserPreferencesService.setPreferredCurrency(value as Currency);
    }
    localStorage.setItem('userPreferredCurrency', value);
    setCurrency(value as Currency);
  }

  return (
    <>
      {options.length > 0 && (
        <Select
          value={defaultOption?.value}
          options={options}
          defaultOption={options.find(
            (option) => option.value === userSetCurrency,
          )}
          placeholderOption={{ label: 'Set Currency', value: '' }}
          onValueChange={(value) => onValueChange(value)}
          variant={variant}
        ></Select>
      )}
    </>
  );
}
