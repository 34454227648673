import React from 'react';
import CurrencyFormattedNumber from '../CurrencyNumber';
import { GivingLevelCardProps } from './interface';
import { useExchangeRateHook } from '@/src/lib/store/exchange-rate';

const GivingLevelCard = ({
  givingLevel,
  currencyCode,
  isActiveCampaign,
  givingLevelLink,
  showClaimed = true,
  handleSelection,
  setIsCustomInputTouched,
}: GivingLevelCardProps) => {
  const {
    ID,
    Title,
    Description,
    Limit,
    PrizeAwarded,
    IncludeEmailNote,
    NoteEmail,
    RequireEmail,
    ChallengeAmount,
  } = givingLevel;

  const givingLevelAmount = useExchangeRateHook(
    ChallengeAmount,
    currencyCode,
    false,
  );

  const handleGivingLevelSelection = () => {
    setIsCustomInputTouched(true);
    if (
      isActiveCampaign &&
      handleSelection &&
      !(Limit > 0 && PrizeAwarded >= Limit)
    ) {
      console.debug('givingLevelAmount.amount', givingLevelAmount.amount);
      handleSelection(givingLevel, givingLevelAmount.amount);
    } else {
      window.location.href = givingLevelLink.replace(
        'GIVING_LEVEL_ID',
        String(ID),
      );
    }
  };

  return (
    <div className="block p-6" onClick={handleGivingLevelSelection}>
      <CurrencyFormattedNumber
        element="h2"
        className="text-2xl font-bold mb-3"
        amount={ChallengeAmount}
        currencyCode={currencyCode}
      />
      <p className="font-bold  text-rebuild-dark mb-1">{Title}</p>
      <p>{Description}</p>

      <div className="flex flex-col gap-1 mt-2">
        {showClaimed && Limit > 0 && (
          <>
            {PrizeAwarded >= Limit && (
              <p className="text-sm text-red-400">Sold out! </p>
            )}

            <p className="text-neutral-500 text-sm">
              {PrizeAwarded} of {Limit} claimed
            </p>
          </>
        )}
        {showClaimed && Limit <= 0 && (
          <p className="text-neutral-500 text-sm">{PrizeAwarded} claimed</p>
        )}
        {RequireEmail && (
          <>
            <p className="text-neutral-500 text-xs italic mt-2">
              By choosing this Giving Level, you agree to share your email with
              the campaign organizer.
            </p>
            {IncludeEmailNote && (
              <p className="text-neutral-500 text-xs italic">
                <strong>Reason provided by the campaign organizer: </strong>
                {NoteEmail}
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default GivingLevelCard;
